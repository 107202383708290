(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
jQuery(document).ready(function ($) {
  var isSafari = navigator.vendor && navigator.vendor.indexOf("Apple") > -1 && navigator.userAgent && navigator.userAgent.indexOf("CriOS") == -1 && navigator.userAgent.indexOf("FxiOS") == -1;
  if (isSafari) {
    var body = document.body;
    body.classList.add("safari-browser");
  }

  // GLOBAL FUNCTION - Identify Firefox User
  var isFirefox = /firefox/i.test(navigator.userAgent);
  if (isFirefox) {
    var _body = document.body;
    _body.classList.add("firefox-browser");
  }
  // GLOBAL FUNCTION - outsideClick
  function outsideClick(element, events, callback) {
    var html = document.documentElement;
    var outside = "data-outside";
    if (!element.hasAttribute(outside)) {
      events.forEach(function (userEvent) {
        setTimeout(function () {
          html.addEventListener(userEvent, handleOutsite);
        });
      });
      element.setAttribute(outside, "");
    }
    function handleOutsite(event) {
      if (!element.contains(event.target)) {
        element.removeAttribute(outside, "");
        events.forEach(function (userEvent) {
          html.removeEventListener(userEvent, handleOutsite);
        });
        callback();
      }
    }
  }

  // GLOBAL FUNCTION - Toast Alert
  var toastContainer = document.querySelector(".toast-container");
  function initToast(status, message) {
    var toast = createToast(status, message);
    toastContainer.appendChild(toast);
    handleClickCloseToast();
    removeToast(toast);
  }
  function createToast(statusToast, messageToast) {
    var toastElement = document.createElement("div");
    toastElement.classList.add("toast-element", statusToast);
    toastElement.innerHTML = "\n        <div class=\"toast-message\">\n          ".concat(messageToast, "\n        </div>\n        <button class=\"toast-close no-config\" data-toast=\"close\">\n          <img src=\"").concat(baseUrl, "/wp-content/themes/ouse_web/img/icon/toast-close.svg\" alt=\"\" />\n        </button>\n        <div class=\"toast-progress\"></div>\n      ");
    return toastElement;
  }
  function removeToast(toastElement, clickedBtnClose) {
    setTimeout(function () {
      toastElement.classList.add("hide");
    }, clickedBtnClose ? 0 : 5000);
    setTimeout(function () {
      toastElement.classList.add("removing-content");
    }, clickedBtnClose ? 500 : 5500);
    setTimeout(function () {
      toastElement.remove();
    }, clickedBtnClose ? 2500 : 8000);
  }
  function handleClickCloseToast() {
    var btnsCloseToast = document.querySelectorAll('[data-toast="close"]');
    if (btnsCloseToast.length) {
      btnsCloseToast.forEach(function (btn) {
        btn.addEventListener("click", function () {
          var toastTargetClicked = this.parentElement;
          removeToast(toastTargetClicked, true);
        });
      });
    }
  }

  // GLOBAL FUNCTION - Steps
  var stepsItens = $("[data-steps-list]");
  if (stepsItens.length) {
    var _settings2;
    stepsItens.slick({
      autoplay: true,
      autoplaySpeed: 5500,
      arrows: true,
      centerMode: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: "<button type='button' class='slick-prev slick-button-arrow-left pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next slick-button-arrow-right pull-right'></button>",
      responsive: [{
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1
        }
      }, {
        breakpoint: 767,
        settings: _defineProperty({
          slidesToShow: 1.5,
          variableWidth: true
        }, "slidesToShow", 1)
      }, {
        breakpoint: 991,
        settings: (_settings2 = {
          slidesToShow: 2.5
        }, _defineProperty(_settings2, "slidesToShow", 1), _defineProperty(_settings2, "variableWidth", true), _settings2)
      }]
    });
  }

  // GLOBAL FUNCTION - Modal
  var modal = document.querySelector("[data-modal]");
  if (modal) {
    var toggleModal = function toggleModal(event) {
      event.preventDefault();
      modal.classList.toggle("active");
    };
    var clickOutsideModal = function clickOutsideModal(event) {
      var isContainer = event.target === this;
      if (isContainer) {
        toggleModal(event);
      }
    };
    var btnOpenModal = document.querySelector('[data-modal="open"]');
    var btnsCloseModal = document.querySelectorAll('[data-modal="close"]');
    btnOpenModal === null || btnOpenModal === void 0 || btnOpenModal.addEventListener("click", toggleModal);
    btnsCloseModal.forEach(function (btn) {
      return btn.addEventListener("click", toggleModal);
    });
    modal.addEventListener("click", clickOutsideModal);
  }

  // Simulator
  $("form.dados-simulador").submit(function (e) {
    e.preventDefault();

    //valida campos
    if (!checkIsValid()) {
      return;
    }

    /* Define o Formulário */
    var simulatorButtonBackStep = document.querySelector('[data-simulator="back-step"].simulator-step-btn-back');
    var simulatorButtonFinish = document.querySelector('[data-simulator="finish"]');
    var form = $(this);
    var formData = new FormData(this);
    var wp_nonce = $(".wp_nonce").val();

    // Active loader
    // simulatorButtonBackStep.classList.add("disabled");
    simulatorButtonFinish.classList.add("disabled");
    simulatorButtonFinish.classList.add("loading");

    /* Faz a Requisição */
    $.ajax({
      url: form.attr("action"),
      data: formData,
      //form.serialize()
      dataType: "json",
      type: "post",
      cache: false,
      contentType: false,
      processData: false,
      headers: {
        authorization: wp_nonce
      },
      beforeSend: function beforeSend(x, o) {
        console.log("Enviando Dados...");
      },
      success: function success(response) {
        if (response.status) {
          initToast("success", response.msg);
          simulatorButtonFinish.classList.add("disabled");
          var query = "nome=" + response.data.nome + "&email=" + response.data.email + "&telefone=" + response.data.telefone + "&cpf=" + response.data.cpf + "&cep=" + response.data.cep + "&cidade=" + response.data.cidade + "&uf=" + response.data.uf + "&toyota=" + response.data.toyota + "&simulacao=" + response.data.simulacao + "&tipo=" + response.data.tipo + "&valor=" + response.data.valor + "&toyota=" + response.data.toyota + "&simulacao=" + response.data.simulacao + "&nova=" + response.data.nova;
          setTimeout(function () {
            window.location.href = baseUrl + "/simulador-resposta/?" + encodeURI(btoa(query));
          }, 2000);
        } else {
          initToast("error", response.msg);
          simulatorButtonFinish.classList.remove("disabled");
          simulatorButtonFinish.classList.remove("loading");
        }
      },
      complete: function complete() {
        // Hide loader
        simulatorButtonFinish.classList.remove("loading");
      },
      error: function error(XMLHttpRequest, textStatus, errorThrown) {
        console.log(errorThrown);
        simulatorButtonFinish.classList.remove("disabled");
        simulatorButtonFinish.classList.remove("loading");
        simulatorButtonBackStep.classList.remove("disabled");
      }
    });
  });
  function checkIsValid() {
    var isAllElementsFilled = true;

    // check others inputs
    var inputsSelectsStep2 = document.querySelectorAll('[data-simulator="2"] select, [data-simulator="2"] input');
    inputsSelectsStep2.forEach(function (item) {
      if (item.hasAttribute("required") && !item.value) {
        item.classList.add("invalid");
        setTimeout(function () {
          return item.classList.remove("invalid");
        }, 2000);
        if (item.dataset.input !== "telefone" && item.dataset.input !== "email" && isAllElementsFilled) {
          isAllElementsFilled = false;
        }
      }
    });
    if (!isAllElementsFilled) {
      initToast("error", "Preencha todos os campos!");
      return false;
    }

    // check email
    var inputEmail = document.querySelector('[data-input="email"]');
    var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
    if (!isValidEmail) {
      initToast("error", "Informe um e-mail válido!");
      inputEmail.classList.add("invalid");
      setTimeout(function () {
        return inputEmail.classList.remove("invalid");
      }, 2000);
      return false;
    }

    // check cpf
    var inputCpf = document.querySelector('.simulator-step-person-date [name="cpf"]');
    var inputDataNascimento = document.querySelector('.simulator-step-person-date [name="data_nascimento"]');
    if (inputCpf) {
      var cpfClean = inputCpf.value.toString().replace(/\.|-/gm, "");
      if (!jsbrasil.validateBr.cpf(cpfClean)) {
        initToast("error", "Informe um numero de CPF válido!");
        return false;
      }
      if (!inputDataNascimento.value) {
        initToast("error", "Informe sua data de nascimento!");
        return false;
      }
    }

    // check 'phone'
    var inputPhone = document.querySelector('[data-input="telefone"]');
    var phoneNumberClean = inputPhone.value.replaceAll(" ", "").replace("(", "").replace(")", "").replace("-", "");
    if (phoneNumberClean.length !== 11) {
      initToast("error", "Informe um numero de telefone válido!");
      inputPhone.classList.add("invalid");
      setTimeout(function () {
        return inputEmail.classList.remove("invalid");
      }, 2000);
    }
    if (phoneNumberClean.length !== 11) {
      return false;
    }

    // check CEP
    var inputCepError = document.querySelector(".box-input-cep.error");
    var inputCep = document.querySelector('[name="cep"]');
    var inputCepValue = inputCep.value;
    if (inputCepError) {
      initToast("error", "Informe um numero de CEP válido!");
      inputCep.classList.add("invalid");
      setTimeout(function () {
        return inputCep.classList.remove("invalid");
      }, 2000);
      return false;
    }
    if (inputCepValue.length !== 9) {
      return false;
    }
    return isAllElementsFilled;
  }

  // HEADER
  var header = document.querySelector("[data-header]");
  if (header) {
    var handleScroll = function handleScroll() {
      var scroll = window.pageYOffset;
      if (scroll > 160) {
        header.classList.add("active");
      } else {
        header.classList.remove("active");
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
  }

  // CPF FORM
  $("form.requisicaoCPF").submit(function (e) {
    e.preventDefault();
    /* Define o Formulário */
    var form = $(this),
      formData = new FormData(this);
    /* Faz a Requisição */

    var loaderBox = $("[data-loader-box]");
    var wp_nonce = $(".wp_nonce").val();
    loaderBox.addClass("loading");
    $.ajax({
      url: form.attr("action"),
      data: formData,
      //form.serialize()
      dataType: "json",
      type: "post",
      cache: false,
      contentType: false,
      processData: false,
      headers: {
        authorization: wp_nonce
      },
      beforeSend: function beforeSend(x, o) {
        console.log("Enviando Dados...");
      },
      success: function success(response) {
        var modalSimulatorResponse = document.querySelector('[data-modal-name="response-form"]');
        $(".status").removeClass("active");
        $(".status-" + response.status).addClass("active");
        modalSimulatorResponse.classList.toggle("active");
        if (response.status) {
          $("#documento").val("");
          $("#email").val("");
        }
      },
      complete: function complete() {
        loaderBox.removeClass("loading");
      },
      error: function error(XMLHttpRequest, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  });

  // Consulta
  $("form.requisicaoConsulta").submit(function (e) {
    e.preventDefault();
    /* Define o Formulário */
    var form = $(this),
      formData = new FormData(this),
      tipo = $(this).data("form");
    /* Faz a Requisição */

    var loaderBox = $("[data-loader-box]");
    var wp_nonce = $(".wp_nonce").val();
    loaderBox.addClass("loading");
    $.ajax({
      url: form.attr("action"),
      data: formData,
      //form.serialize()
      dataType: "json",
      type: "post",
      cache: false,
      contentType: false,
      processData: false,
      headers: {
        authorization: wp_nonce
      },
      beforeSend: function beforeSend(x, o) {
        console.log("Enviando Dados...");
      },
      success: function success(response) {
        $("." + tipo + " .status").removeClass("active");
        setTimeout(function () {
          $("." + tipo + " .status-" + response.status).addClass("active");
        }, 600);
      },
      complete: function complete() {
        loaderBox.removeClass("loading");
      },
      error: function error(XMLHttpRequest, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  });

  //FIX CONTACT FORM 7 SUBMIT
  function fixCF7MultiSubmit() {
    jQuery('input.wpcf7-submit[type="submit"]').click(function () {
      var disabled = jQuery(this).attr("data-disabled");
      if (disabled && disabled == "disabled") {
        return false;
      } else {
        jQuery(this).attr("data-disabled", "disabled");
        return true;
      }
    });
    jQuery(".wpcf7").bind("wpcf7submit", function () {
      jQuery(this).find('input.wpcf7-submit[type="submit"]').attr("data-disabled", "enabled");
    });
  }
  fixCF7MultiSubmit();

  // HEADER BTN MOBILE
  var btn = document.querySelector(".header-btn");
  if (btn) {
    var handleClickBtnMobile = function handleClickBtnMobile(event) {
      event.preventDefault();
      var html = document.documentElement;
      var headerNav = document.querySelector(".header-nav");
      html.classList.toggle("no-scroll");
      btn.classList.toggle("active");
      headerNav.classList.toggle("active");
    };
    btn.addEventListener("click", handleClickBtnMobile);
  }

  // SUBMENU (MOBILE)
  var linksMenu = document.querySelectorAll(".header-nav ul li.menu-item-has-children > a");
  var isDevice991pxMax = window.matchMedia("(max-width: 991px)").matches;
  if (linksMenu.length && isDevice991pxMax) {
    var handleClickBtnMobile2 = function handleClickBtnMobile2(indexTarget) {
      linksMenu.forEach(function (link, index) {
        if (indexTarget === index) return;
        var subMenu = link.nextElementSibling;
        link.classList.remove("active");
        subMenu.classList.remove("active");
      });
      linksMenu[indexTarget].classList.toggle("active");
      linksMenu[indexTarget].nextElementSibling.classList.toggle("active"); // Submenu
    };

    linksMenu.forEach(function (link, index) {
      link.addEventListener("click", function () {
        handleClickBtnMobile2(index);
      });
    });
  }

  // HOME - HERO SLIDER
  var homeHeroBanners = $('[data-home-hero="wrapper"]');
  if (homeHeroBanners.length) {
    var qtdHomeHeroItens = homeHeroBanners.children().length;
    var homeHeroControlsContainer = $('[data-home-hero="controls"] .container');
    homeHeroBanners.slick({
      slidesToShow: 1,
      dots: true,
      appendDots: homeHeroControlsContainer,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 6500,
      prevArrow: $('[data-home-hero="controls"] .slick-prev'),
      nextArrow: $('[data-home-hero="controls"] .slick-next')
    });
    if (qtdHomeHeroItens >= 2) {
      setTimeout(function () {
        var homeHeroControlsButtons = $('[data-home-hero="controls"] .home-hero-controls-buttons');
        var homeHeroControlsDots = $('[data-home-hero="controls"] .slick-dots');
        homeHeroControlsButtons.addClass("loaded");
        homeHeroControlsDots.addClass("loaded");
      }, 1000);
    }
  }

  // HOME - CARS SLIDER - SLICK JS
  var homeCars = $("[data-home-cars]");
  if (homeCars.length) {
    homeCars.slick({
      slidesToShow: 3,
      dots: false,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 6500,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 685,
        settings: {
          slidesToShow: 1
        }
      }, {
        breakpoint: 991,
        settings: {
          slidesToShow: 2
        }
      }]
    });
  }

  // HOME - BENEFITS
  var boxDetailsBenefits = document.querySelectorAll('[data-benefits="box-details"]');
  if (boxDetailsBenefits.length) {
    var activeBoxDetails = function activeBoxDetails(index) {
      event.preventDefault();
      boxDetailsBenefits[index].classList.add("details-text");
    };
    var disableBoxDetails = function disableBoxDetails(index) {
      event.preventDefault();
      boxDetailsBenefits[index].classList.remove("details-text");
    };
    var btnsOpenDetails = document.querySelectorAll('[data-benefits="box-details"] a.benefits-box-details');
    var btnsCloseDetails = document.querySelectorAll('[data-benefits="box-details"] button');
    btnsOpenDetails.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        activeBoxDetails(index);
      });
    });
    btnsCloseDetails.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        disableBoxDetails(index);
      });
    });
  }

  // CONSORTIUM ANIMATION
  var linesHorizontal = document.querySelectorAll('[data-consortium="line"]');
  if (linesHorizontal.length) {
    var animationConsortiumItens = function animationConsortiumItens() {
      var isDeviceDesktop = window.matchMedia("(min-width: 992px)").matches;
      var isDeviceTable = window.matchMedia("(min-width: 768px) and (max-width: 991px)").matches;
      var isDeviceMobile = window.matchMedia("(min-width: 481px) and (max-width: 767px)").matches;
      var isDeviceMobileSmall = window.matchMedia("(min-width: 375px) and (max-width: 480px)").matches;
      var isDeviceMobileXSmall = window.matchMedia("(max-width: 374px)").matches;
      if (isDeviceDesktop || isDeviceTable) {
        linesHorizontal.forEach(function (line, index) {
          var elementTop = line.getBoundingClientRect().top;
          var isElementVisible = elementTop - windowHalf < 0;
          if (isElementVisible) {
            if (line.classList.contains("active")) return;
            line.classList.add("active");

            // verificando qual é o device ---- (DEPOIS) VERICAR EM "OUTRO LUGAR" PARA RODAR UMA VEZ APENAS
            if (isDeviceDesktop) {
              initLineAnimation(line, index);
            } else if (isDeviceTable) {
              initLineAnimationTablet(line, index);
            }
          }
        });
      } else if (isDeviceMobile) {
        initLineAnimationMobile();
      } else if (isDeviceMobileSmall || isDeviceMobileXSmall) {
        initLineAnimationMobileSmall();
      }
    };
    var initLineAnimation = function initLineAnimation(lineTarget, index) {
      var indexEdit = index + 1;
      var consortiumBox = document.querySelector("[data-consortium-main]");
      consortiumBox.dataset.consortiumLineActive = indexEdit;
      if (indexEdit === 1 && !linesHorizontal01WasAnimated) {
        var consortiumItens = document.querySelectorAll(".consortium-item");
        linesHorizontal01WasAnimated = true;
        consortiumItens[0].classList.add("active");
        setTimeout(function () {
          var divChildren = lineTarget.children[0];
          divChildren.style.width = "455px";
          setTimeout(function () {
            consortiumItens[1].classList.add("active");
            setTimeout(function () {
              divChildren.style.width = "100%";
              setTimeout(function () {
                var lineVertical01 = document.querySelector(".line-vertical-1");
                lineVertical01.children[0].style.height = "100%";
                setTimeout(function () {
                  // INDICANDO QUANDO ANIMAÇÃO CHEGOU AO FINAL
                  linesHorizontal[0].classList.add("has-finished-animation");
                }, 1900);
              }, 1300);
            }, 1300);
          }, 800);
        }, 500);
        return;
      }
      if (indexEdit === 2 && !linesHorizontal02WasAnimated) {
        var initAnimation = function initAnimation() {
          var line2 = document.querySelector(".line-horizontal-2");
          line2.children[0].style.width = "370px";
          setTimeout(function () {
            var consortiumItens = document.querySelectorAll(".consortium-item");
            consortiumItens[3].classList.add("active");
            setTimeout(function () {
              line2.children[0].style.width = "815px";
              setTimeout(function () {
                consortiumItens[2].classList.add("active");
                setTimeout(function () {
                  line2.children[0].style.width = "100%";
                  setTimeout(function () {
                    var lineVertical02 = document.querySelector(".line-vertical-2");
                    lineVertical02.children[0].style.height = "100%";
                    setTimeout(function () {
                      // INDICANDO QUANDO ANIMAÇÃO CHEGOU AO FINAL
                      linesHorizontal[1].classList.add("has-finished-animation");
                    }, 1200);
                  }, 900);
                }, 900);
              }, 1000);
            }, 800);
          }, 900);
        };
        linesHorizontal02WasAnimated = true;
        if (!linesHorizontal[0].classList.contains("has-finished-animation")) {
          var handleMutation = function handleMutation(mutation) {
            if (mutation[0].target.classList.contains("has-finished-animation")) {
              observer.disconnect();
              initAnimation();
            }
          };
          var observerTarget = linesHorizontal[0];
          var observer = new MutationObserver(handleMutation);
          observer.observe(observerTarget, {
            attributes: true
          });
        } else {
          initAnimation();
        }
      }
      if (indexEdit === 3 && !linesHorizontal03WasAnimated) {
        var initAnimation02 = function initAnimation02() {
          var line3 = document.querySelector(".line-horizontal-3");
          line3.children[0].style.width = "100px";
          setTimeout(function () {
            var consortiumItens = document.querySelectorAll(".consortium-item");
            consortiumItens[4].classList.add("active");
            setTimeout(function () {
              line3.children[0].style.width = "100%";
              setTimeout(function () {
                consortiumItens[5].classList.add("active");
              }, 1200);
            }, 1200);
          }, 900);
        };
        linesHorizontal03WasAnimated = true;
        if (!linesHorizontal[1].classList.contains("has-finished-animation")) {
          var _handleMutation = function _handleMutation(mutation) {
            if (mutation[0].target.classList.contains("has-finished-animation")) {
              _observer.disconnect();
              initAnimation02();
            }
          };
          var _observerTarget = linesHorizontal[1];
          var _observer = new MutationObserver(_handleMutation);
          _observer.observe(_observerTarget, {
            attributes: true
          });
        } else {
          initAnimation02();
        }
      }
    };
    var initLineAnimationTablet = function initLineAnimationTablet(lineTarget, index) {
      var indexEdit = index + 1;
      var consortiumBox = document.querySelector("[data-consortium-main]");
      consortiumBox.dataset.consortiumLineActive = indexEdit;
      if (indexEdit === 1 && !linesHorizontal01WasAnimated) {
        var consortiumItens = document.querySelectorAll(".consortium-item");
        linesHorizontal01WasAnimated = true;
        consortiumItens[0].classList.add("active");
        setTimeout(function () {
          var divChildren = lineTarget.children[0];
          divChildren.style.width = "330px";
          setTimeout(function () {
            consortiumItens[1].classList.add("active");
            setTimeout(function () {
              divChildren.style.width = "100%";
              setTimeout(function () {
                var lineVertical01 = document.querySelector(".line-vertical-1");
                lineVertical01.children[0].style.height = "100%";
                setTimeout(function () {
                  // INDICANDO QUANDO ANIMAÇÃO CHEGOU AO FINAL
                  linesHorizontal[0].classList.add("has-finished-animation");
                }, 1300);
              }, 1000);
            }, 700);
          }, 900);
        }, 800);
        return;
      }
      if (indexEdit === 2 && !linesHorizontal02WasAnimated) {
        var initAnimation = function initAnimation() {
          var line2 = document.querySelector(".line-horizontal-2");
          line2.children[0].style.width = "310px";
          setTimeout(function () {
            var consortiumItens = document.querySelectorAll(".consortium-item");
            consortiumItens[3].classList.add("active");
            setTimeout(function () {
              line2.children[0].style.width = "624px";
              setTimeout(function () {
                consortiumItens[2].classList.add("active");
                setTimeout(function () {
                  line2.children[0].style.width = "100%";
                  setTimeout(function () {
                    var lineVertical02 = document.querySelector(".line-vertical-2");
                    lineVertical02.children[0].style.height = "100%";
                    setTimeout(function () {
                      // INDICANDO QUANDO ANIMAÇÃO CHEGOU AO FINAL
                      linesHorizontal[1].classList.add("has-finished-animation");
                    }, 1200);
                  }, 900);
                }, 800);
              }, 1000);
            }, 800);
          }, 900);
        };
        linesHorizontal02WasAnimated = true;
        if (!linesHorizontal[0].classList.contains("has-finished-animation")) {
          var handleMutation = function handleMutation(mutation) {
            if (mutation[0].target.classList.contains("has-finished-animation")) {
              observer.disconnect();
              initAnimation();
            }
          };
          var observerTarget = linesHorizontal[0];
          var observer = new MutationObserver(handleMutation);
          observer.observe(observerTarget, {
            attributes: true
          });
        } else {
          initAnimation();
        }
      }
      if (indexEdit === 3 && !linesHorizontal03WasAnimated) {
        var initAnimation02 = function initAnimation02() {
          var line3 = document.querySelector(".line-horizontal-3");
          line3.children[0].style.width = "70px";
          setTimeout(function () {
            var consortiumItens = document.querySelectorAll(".consortium-item");
            consortiumItens[4].classList.add("active");
            setTimeout(function () {
              line3.children[0].style.width = "100%";
              setTimeout(function () {
                consortiumItens[5].classList.add("active");
              }, 1200);
            }, 1000);
          }, 700);
        };
        linesHorizontal03WasAnimated = true;
        if (!linesHorizontal[1].classList.contains("has-finished-animation")) {
          var _handleMutation2 = function _handleMutation2(mutation) {
            if (mutation[0].target.classList.contains("has-finished-animation")) {
              _observer2.disconnect();
              initAnimation02();
            }
          };
          var _observerTarget2 = linesHorizontal[1];
          var _observer2 = new MutationObserver(_handleMutation2);
          _observer2.observe(_observerTarget2, {
            attributes: true
          });
        } else {
          initAnimation02();
        }
      }
    };
    var initLineAnimationMobile = function initLineAnimationMobile() {
      var consortiumItens = document.querySelectorAll(".consortium-item");
      var windowHalf = window.innerHeight * 0.73;
      if (consortiumItens.length) {
        consortiumItens.forEach(function (item, index) {
          var elementTop = item.getBoundingClientRect().top;
          var isElementVisible = elementTop - windowHalf < 0;
          var lineVerticalMobile = document.querySelector(".line-vertical-2.hide-desktop");
          if (isElementVisible && !item.classList.contains("active")) {
            if (index === 0) {
              item.classList.add("active");
              lineVerticalMobile.children[0].style.height = "270px";
              setTimeout(function () {
                item.classList.add("has-finished-animation");
              }, 1000);
            }
            if (index === 1 && consortiumItens[0].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "660px";
              }, 800);
            }
            if (index === 2 && consortiumItens[1].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "975px";
              }, 800);
            }
            if (index === 3 && consortiumItens[2].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "1300px";
              }, 800);
            }
            if (index === 4 && consortiumItens[3].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "100%";
                setTimeout(function () {
                  consortiumItens[consortiumItens.length - 1].classList.add("active");
                }, 1000);
              }, 800);
            }
          }
        });
      }
    };
    var initLineAnimationMobileSmall = function initLineAnimationMobileSmall() {
      var consortiumItens = document.querySelectorAll(".consortium-item");
      var windowHalf = window.innerHeight * 0.73;
      if (consortiumItens.length) {
        consortiumItens.forEach(function (item, index) {
          var elementTop = item.getBoundingClientRect().top;
          var isElementVisible = elementTop - windowHalf < 0;
          var lineVerticalMobile = document.querySelector(".line-vertical-2.hide-desktop");
          if (isElementVisible && !item.classList.contains("active")) {
            if (index === 0) {
              item.classList.add("active");
              lineVerticalMobile.children[0].style.height = "270px";
              setTimeout(function () {
                item.classList.add("has-finished-animation");
              }, 1000);
            }
            if (index === 1 && consortiumItens[0].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "545px";
              }, 800);
            }
            if (index === 2 && consortiumItens[1].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "800px";
              }, 800);
            }
            if (index === 3 && consortiumItens[2].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "1100px";
              }, 800);
            }
            if (index === 4 && consortiumItens[3].classList.contains("has-finished-animation")) {
              item.classList.add("active");
              setTimeout(function () {
                item.classList.add("has-finished-animation");
                lineVerticalMobile.children[0].style.height = "100%";
                setTimeout(function () {
                  consortiumItens[consortiumItens.length - 1].classList.add("active");
                }, 1000);
              }, 800);
            }
          }
        });
      }
    };
    var windowHalf = window.innerHeight * 0.73;
    var linesHorizontal01WasAnimated = false;
    var linesHorizontal02WasAnimated = false;
    var linesHorizontal03WasAnimated = false;
    animationConsortiumItens();
    window.addEventListener("scroll", animationConsortiumItens);
  }

  // TESTIMONIALS SLIDER (TROCAR PARA SLICK)
  // const swiper4 = new Swiper('[data-home-testimonials] .swiper', {
  //   slidesPerView: 1.2,
  //   spaceBetween: 22,
  //   centeredSlides: true,

  //   navigation: {
  //     nextEl: '[data-home-testimonials] .swiper-button-next',
  //     prevEl: '[data-home-testimonials] .swiper-button-prev',
  //   },
  //   breakpoints: {
  //     768: {
  //       slidesPerView: 2.6,
  //       spaceBetween: 32,
  //       centeredSlides: false,
  //     },
  //     1081: {
  //       slidesPerView: 3.8,
  //       spaceBetween: 32,
  //       centeredSlides: false,
  //     },
  //   },
  // });

  // // TESTIMONIALS SLIDER - CUSTOM
  // const btnPrev = document.querySelector(
  //   '.testimonials-buttons .swiper-button-prev',
  // );
  // const btnNext = document.querySelector(
  //   '.testimonials-buttons .swiper-button-next',
  // );

  // if (btnPrev || btnNext) {
  //   const testimonialsItens = document.querySelectorAll('.testimonials-item');
  //   const lastTestimonialsItem =
  //     testimonialsItens[testimonialsItens.length - 1];
  //   const isDevice992pxMin = window.matchMedia('(min-width: 992px)').matches;

  //   if (isDevice992pxMin) {
  //     function handleClick(event) {
  //       event.preventDefault();

  //       if (btnNext.classList.contains('swiper-button-disabled')) {
  //         lastTestimonialsItem.style.opacity = '1';
  //       } else {
  //         lastTestimonialsItem.style.opacity = '0.2';
  //       }
  //     }

  //     btnPrev.addEventListener('click', handleClick);
  //     btnNext.addEventListener('click', handleClick);
  //   }
  // }

  // BLOG HOME SLIDER
  // const homePostsBlog = $("[data-home-blog] .blog-home-main");

  // if (homePostsBlog.length) {
  //   homePostsBlog.slick({
  //     arrows: true,
  //     autoplay: false,
  //     slidesToShow: 3,
  //     infinite: false,
  //     slidesPerView: 1,
  //     prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
  //     nextArrow:
  //       "<button type='button' class='slick-next pull-right'></button>",
  //     responsive: [
  //       {
  //         breakpoint: 991,
  //         settings: {
  //           centerMode: false,
  //           slidesToShow: 2.6,
  //         },
  //       },
  //       {
  //         breakpoint: 767,
  //         settings: {
  //           slidesToShow: 2.2,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1.6,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1.2,
  //         },
  //       },
  //     ],
  //   });
  // }

  // HIDE BTNS FIXED ON FOOTER SECTION
  var isHomePage = document.querySelector(".page-template-home");
  if (isHomePage) {
    var footer = document.querySelector("footer");
    var carsSection = document.querySelector(".cars");
    if (footer && carsSection) {
      var toggleBtnsFixedOnFooter = function toggleBtnsFixedOnFooter() {
        var elementTop = footer.getBoundingClientRect().top;
        var isFooterElementVisible = elementTop - _windowHalf < 0;
        var carsSectionTop = carsSection.getBoundingClientRect().top;
        var iscarsSectionTopVisible = carsSectionTop - window.innerHeight * 0.5 < 0;

        // Quando estiver na primeira dobra
        if (iscarsSectionTopVisible) {
          btnWhats === null || btnWhats === void 0 || btnWhats.classList.remove("hide");
          btnSimulation.classList.remove("hide");
        } else {
          btnWhats === null || btnWhats === void 0 || btnWhats.classList.add("hide");
          btnSimulation.classList.add("hide");
        }

        // Quando chegar no footer
        if (isFooterElementVisible) {
          if (footer.classList.contains("active")) return;
          footer.classList.add("active");
          btnWhats === null || btnWhats === void 0 || btnWhats.classList.add("force-hide");
          btnSimulation.classList.add("force-hide");
        } else {
          if (!footer.classList.contains("active")) return;
          footer.classList.remove("active");
          btnWhats === null || btnWhats === void 0 || btnWhats.classList.remove("force-hide");
          btnSimulation.classList.remove("force-hide");
        }
      };
      var btnWhats = document.querySelector(".btn-whatsapp");
      var btnSimulation = document.querySelector(".btn-simulator");
      var _windowHalf = window.innerHeight * 0.85;
      toggleBtnsFixedOnFooter();
      window.addEventListener("scroll", toggleBtnsFixedOnFooter);
    }
  } else {
    var _footer = document.querySelector("footer");
    if (_footer) {
      var _toggleBtnsFixedOnFooter = function _toggleBtnsFixedOnFooter() {
        var elementTop = _footer.getBoundingClientRect().top;
        var isFooterElementVisible = elementTop - _windowHalf2 < 0;

        // Quando chegar no footer
        if (isFooterElementVisible) {
          if (_footer.classList.contains("active")) return;
          _footer.classList.add("active");
          _btnWhats === null || _btnWhats === void 0 || _btnWhats.classList.add("force-hide");
          _btnSimulation.classList.add("force-hide");
        } else {
          if (!_footer.classList.contains("active")) return;
          _footer.classList.remove("active");
          _btnWhats === null || _btnWhats === void 0 || _btnWhats.classList.remove("force-hide");
          _btnSimulation.classList.remove("force-hide");
        }
      };
      var _btnWhats = document.querySelector(".btn-whatsapp");
      var _btnSimulation = document.querySelector(".btn-simulator");
      var _windowHalf2 = window.innerHeight * 0.85;
      _btnWhats === null || _btnWhats === void 0 || _btnWhats.classList.remove("hide");
      _btnSimulation.classList.remove("hide");
      _btnWhats === null || _btnWhats === void 0 || _btnWhats.classList.add("active");
      _btnSimulation.classList.add("active");
      _toggleBtnsFixedOnFooter();
      window.addEventListener("scroll", _toggleBtnsFixedOnFooter);
    }
  }

  // REGULATION - MAIN TABS
  var btnsRegulationOption = document.querySelectorAll('[data-regulation="options"] button');
  var contentsRegulationOption = document.querySelectorAll('[data-regulation="content"] > ul > li');
  if (btnsRegulationOption.length && contentsRegulationOption.length) {
    var checkIsParameter = function checkIsParameter() {
      var hrefArr = location.href.split("/");
      var parameter = hrefArr[hrefArr.length - 1];
      if (parameter.includes("#")) {
        if (parameter === "#calendarios") {
          var namesCategories = document.querySelectorAll("[data-regulation-option]");
          [].concat(_toConsumableArray(btnsRegulationOption), _toConsumableArray(contentsRegulationOption)).forEach(function (item) {
            item.classList.remove("active");
          });
          namesCategories.forEach(function (item, index) {
            if (item.dataset.regulationOption === "calendarios") {
              btnsRegulationOption[index].classList.add("active");
              contentsRegulationOption[index].classList.add("active");
            }
          });
        }
      }
    };
    var handleClickRegulationBtn = function handleClickRegulationBtn(index) {
      [].concat(_toConsumableArray(btnsRegulationOption), _toConsumableArray(contentsRegulationOption)).forEach(function (item) {
        item.classList.remove("active");
      });
      btnsRegulationOption[index].classList.add("active");
      contentsRegulationOption[index].classList.add("active");
    };
    checkIsParameter();
    btnsRegulationOption.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        handleClickRegulationBtn(index);
      });
    });
  }

  // REGULATION - CHILDREN TABS
  var btnsChildrenRegulation = document.querySelectorAll("[data-box-regulamentos-btn]");
  if (btnsChildrenRegulation.length) {
    var toggleRegulationBox = function toggleRegulationBox(indexTarget) {
      [].concat(_toConsumableArray(btnsChildrenRegulation), _toConsumableArray(contentsChildrenRegulation)).forEach(function (item) {
        item.classList.remove("active");
      });
      btnsChildrenRegulation[indexTarget].classList.add("active");
      contentsChildrenRegulation[indexTarget].classList.add("active");
    };
    var contentsChildrenRegulation = document.querySelectorAll("[data-box-regulamentos-content]");
    btnsChildrenRegulation.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        if (btn.classList.contains("active")) return;
        toggleRegulationBox(index);
      });
    });
  }

  // FAQ
  var faqBox = document.querySelector("[data-faq]");
  if (faqBox) {
    var faqTitles = document.querySelectorAll("[data-faq] dt");
    var faqDescriptions = document.querySelectorAll("[data-faq] dd");
    if (faqTitles.length && faqDescriptions.length) {
      var handleClickTitle = function handleClickTitle(index) {
        [].concat(_toConsumableArray(faqTitles), _toConsumableArray(faqDescriptions)).forEach(function (item) {
          item.classList.remove("active");
        });
        faqTitles[index].classList.add("active");
        faqDescriptions[index].classList.add("active");
      };
      faqTitles.forEach(function (title, index) {
        title.addEventListener("click", function (event) {
          event.preventDefault();
          handleClickTitle(index);
        });
      });
    }
  }

  // DEMONSTRATIONS OPTIONS
  var demonstrationBox = document.querySelector("[data-demonstrations]");
  if (demonstrationBox) {
    var demonstrationsOptionsBtn = document.querySelectorAll('[data-demonstrations="options"] button');
    var demonstrationsContent = document.querySelectorAll('[data-demonstrations="content"] > div');
    if (demonstrationsOptionsBtn.length && demonstrationsContent.length) {
      var _handleClickTitle = function _handleClickTitle(index) {
        [].concat(_toConsumableArray(demonstrationsOptionsBtn), _toConsumableArray(demonstrationsContent)).forEach(function (item) {
          item.classList.remove("active");
        });
        demonstrationsOptionsBtn[index].classList.add("active");
        demonstrationsContent[index].classList.add("active");
      };
      demonstrationsOptionsBtn.forEach(function (title, index) {
        title.addEventListener("click", function (event) {
          event.preventDefault();
          _handleClickTitle(index);
        });
      });
    }
  }
  var tableGroup = $("#tabela-grupos");
  if (tableGroup.length) {
    tableGroup.DataTable({
      language: {
        search: "_INPUT_ <img src=\"".concat(baseUrl, "/wp-content/themes/ouse_web/img/icon/groups-search.svg\" />"),
        searchPlaceholder: "Buscar por",
        lengthMenu: "_MENU_ Resultado por página",
        zeroRecords: "Não foi encontrado nenhum registro",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "Nenhum registro disponível",
        infoFiltered: "(total de _MAX_ registro(s))",
        paginate: {
          previous: "Anterior",
          next: "Próximo"
        }
      }
    });
  }

  // CONTENTS - PDFs AND VIDEOS SLIDERS
  $('[data-contents="pdf-list"], [data-contents="videos-list"]').slick({
    slidesToShow: 4,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
    nextArrow: "<button type='button' class='slick-next pull-right'></button>",
    responsive: [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }]
  });

  // CONTENTS - MODAL VIDEO
  var modalVideoContents = document.querySelector('[data-modal-custom="contents"]');
  if (modalVideoContents) {
    var openModal = function openModal(modalVideoId) {
      var iframe = "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/".concat(modalVideoId, "?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>");
      var iframeVideoArea = document.querySelector('[data-modal-custom="contents"] .modal-contents-iframe');
      iframeVideoArea.innerHTML = iframe;
      modalVideoContents.classList.add("active");
    };
    var hideModal = function hideModal() {
      var iframe = document.querySelector('[data-modal-custom="contents"] iframe');
      iframe.remove();
      modalVideoContents.classList.remove("active");
    };
    var _clickOutsideModal = function _clickOutsideModal(event) {
      var isContainer = event.target === this;
      if (isContainer) {
        hideModal(event);
      }
    };
    var btnsOpenModal = document.querySelectorAll('[data-modal-custom="contents-open"]');
    var btnCloseModal = document.querySelector('[data-modal-custom="contents-close"]');
    btnsOpenModal.length && btnsOpenModal.forEach(function (btn) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        var modalVideo = this.dataset.modalVideo;
        openModal(modalVideo);
      });
    });
    btnCloseModal.addEventListener("click", hideModal);
    modalVideoContents.addEventListener("click", _clickOutsideModal);
  }

  // MODAL DEFAULT
  var modalDefault = document.querySelector('[data-modal-custom="default"]');
  if (modalDefault) {
    var _openModal = function _openModal() {
      modalDefault.classList.add("active");
    };
    var _hideModal = function _hideModal() {
      modalDefault.classList.remove("active");
    };
    var _clickOutsideModal2 = function _clickOutsideModal2(event) {
      var isContainer = event.target === this;
      if (isContainer) {
        _hideModal(event);
      }
    };
    var _btnsOpenModal = document.querySelectorAll('[data-modal-custom="contents-open"]');
    var _btnCloseModal = document.querySelector('[data-modal-custom="contents-close"]');
    _btnsOpenModal.length && _btnsOpenModal.forEach(function (btn) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        _openModal();
      });
    });
    _btnCloseModal.addEventListener("click", _hideModal);
    modalDefault.addEventListener("click", _clickOutsideModal2);
  }

  // BLOG POST - Feedback after copy URL was clicked
  var shareLink = document.querySelector('[data-clipboard-target="#copiarLink"]');
  if (shareLink) {
    var handleMouseleaveShareLink = function handleMouseleaveShareLink() {
      setTimeout(function () {
        tooltipShareLink.innerText = "Copiar URL!";
        tooltipShareLink.classList.remove("copied");
      }, 400);
    };
    var handleClickShareLink = function handleClickShareLink() {
      tooltipShareLink.innerText = "URL Copiada!";
      tooltipShareLink.classList.add("copied");
      shareLink.addEventListener("mouseleave", handleMouseleaveShareLink);
    };
    var tooltipShareLink = document.querySelector('[data-clipboard-target="tooltip"]');
    shareLink.addEventListener("click", handleClickShareLink);
  }

  // ABOUT US - Purpose
  var boxDetailsPurpose = document.querySelectorAll('[data-purpose="box-details"]');
  if (boxDetailsPurpose.length) {
    var _activeBoxDetails = function _activeBoxDetails(index) {
      event.preventDefault();
      boxDetailsPurpose[index].classList.add("details-text");
    };
    var _disableBoxDetails = function _disableBoxDetails(index) {
      event.preventDefault();
      boxDetailsPurpose[index].classList.remove("details-text");
    };
    var _btnsOpenDetails = document.querySelectorAll('[data-purpose="box-details"] a.purpose-box-details');
    var _btnsCloseDetails = document.querySelectorAll('[data-purpose="box-details"] button');
    _btnsOpenDetails.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        _activeBoxDetails(index);
      });
    });
    _btnsCloseDetails.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        _disableBoxDetails(index);
      });
    });
  }

  // ABOUT US - Video
  var videoSection = document.querySelector("[data-video-toyota]");
  if (videoSection) {
    var handleScrollVideoSection = function handleScrollVideoSection() {
      var windowHalf = window.innerHeight * 0.2;
      var elementTop = videoSection.getBoundingClientRect().top;
      var isElementVisible = elementTop - windowHalf < 0;
      if (isElementVisible) {
        var video = videoSection.querySelector("video");
        video.play();
        setTimeout(function () {
          videoSection.classList.add("active");
          window.removeEventListener("scroll", handleScrollVideoSection);
        }, 200);
      }
    };
    handleScrollVideoSection();
    window.addEventListener("scroll", handleScrollVideoSection);
  }

  // ABOUT US - CARS SLIDER - SLICK JS
  if ($("[data-explore-cars-slider]").length) {
    $("[data-explore-cars-slider]").slick({
      slidesToShow: 3,
      dots: false,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      autoplaySpeed: 6500,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 991,
        variableWidth: false,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 685,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }

  // ABOUT US - TIMELINE
  var timeline = document.querySelector("[data-timeline]");
  if (timeline) {
    // ABOUT US - TIMELINE TYPE (MOBILE)
    var _isDevice991pxMax = window.matchMedia("(max-width: 991px)").matches;
    if (_isDevice991pxMax) {
      var timelineType = document.querySelector("[data-timeline-type]");
      timelineType.dataset.timelineType = "click";
    }
    var timelineButtonItens = document.querySelectorAll('[data-timeline="item"] button');
    var timelineYearItens = document.querySelectorAll('[data-timeline="item"] > span');
    if (timelineButtonItens.length && timelineYearItens.length) {
      var typeEffect = document.querySelector("[data-timeline-type]").dataset;
      if (typeEffect.timelineType === "scroll") {
        var _handleScrollVideoSection = function _handleScrollVideoSection() {
          var windowHalf = window.innerHeight * 0.4;
          aboutItens.forEach(function (item) {
            var elementTop = item.getBoundingClientRect().top;
            var isElementVisible = elementTop - windowHalf < 0;
            if (isElementVisible) {
              item.classList.add("active");
            } else {
              item.classList.remove("active");
            }
          });
        };
        var aboutItens = document.querySelectorAll('[data-timeline="item"]');
        window.addEventListener("scroll", _handleScrollVideoSection);
      } else {
        var handleOnMouse = function handleOnMouse(indexTarget) {
          var aboutItens = document.querySelectorAll('[data-timeline="item"]');
          aboutItens[indexTarget].classList.toggle("active");
        };
        timelineYearItens.forEach(function (item, index) {
          item.addEventListener("click", function () {
            return handleOnMouse(index);
          });
        });
        timelineButtonItens.forEach(function (item, index) {
          item.addEventListener("click", function () {
            return handleOnMouse(index);
          });
        });
      }
    }
  }

  // ABOUT US - VIDEO CONTROL
  var btnsVideoControl = document.querySelectorAll(".toyota-video-control");
  if (btnsVideoControl.length) {
    var handleClickVideo = function handleClickVideo(event) {
      event.preventDefault();
      var typeAction = this.dataset.videoControl;
    };
    var video = document.querySelector("[data-video]");
    btnsVideoControl.forEach(function (btn) {
      btn.addEventListener("click", handleClickVideo);
    });
  }

  // SIMULATOR - MONTHS NEXT STEPS
  var btnsNextStep = document.querySelectorAll('[data-simulator="next-step"]');
  if (btnsNextStep.length) {
    var checkStep2isValid = function checkStep2isValid() {
      var isAllElementsFilled = true;

      // check others inputs
      var inputsSelectsStep2 = document.querySelectorAll('[data-simulator="2"] select, [data-simulator="2"] input');
      inputsSelectsStep2.forEach(function (item) {
        if (item.hasAttribute("required") && !item.value) {
          item.classList.add("invalid");
          setTimeout(function () {
            return item.classList.remove("invalid");
          }, 2000);
          if (item.dataset.input !== "telefone" && item.dataset.input !== "email" && isAllElementsFilled) {
            isAllElementsFilled = false;
          }
        }
      });
      if (!isAllElementsFilled) {
        initToast("error", "Preencha todos os campos!");
        return;
      }

      // check email
      var inputEmail = document.querySelector('[data-input="email"]');
      var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
      if (!isValidEmail) {
        initToast("error", "Informe um e-mail válido!");
        inputEmail.classList.add("invalid");
        setTimeout(function () {
          return inputEmail.classList.remove("invalid");
        }, 2000);
        return;
      }

      // check cpf
      var inputCpf = document.querySelector('.simulator-step-person-date [name="cpf"]');
      var inputDataNascimento = document.querySelector('.simulator-step-person-date [name="data_nascimento"]');
      var cpfClean = inputCpf.value.toString().replace(/\.|-/gm, "");
      if (!jsbrasil.validateBr.cpf(cpfClean)) {
        initToast("error", "Informe um numero de CPF válido!");
        return;
      }
      if (!inputDataNascimento.value) {
        initToast("error", "Informe sua data de nascimento!");
        return;
      }
      return;
    };
    var handleClickBtnNextStep = function handleClickBtnNextStep(index) {
      // const simutorTop = document.querySelector('[simulator-top]');
      var simulatorBox = document.querySelectorAll("[data-simulator-box]");
      var simulatorText = document.querySelectorAll("[data-simulator-text]");

      // if (index + 1 === 1) {
      //   if (checkStep1isValid()) {
      //     [...simulatorBox, ...simulatorText].forEach((item) =>
      //       item.classList.remove("active")
      //     );
      //     simulatorText[index + 1].classList.add("active");
      //     simulatorBox[index + 1].classList.add("active");
      //   }
      //   return;
      // }

      if (index + 1 === 2) {
        if (checkStep2isValid()) {
          [].concat(_toConsumableArray(simulatorBox), _toConsumableArray(simulatorText)).forEach(function (item) {
            return item.classList.remove("active");
          });
          simulatorText[index + 1].classList.add("active");
          simulatorBox[index + 1].classList.add("active");
        }
        return;
      }
      simulatorBox.forEach(function (item) {
        return item.classList.remove("active");
      });
      simulatorBox[index + 1].classList.add("active");
    };
    btnsNextStep.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        handleClickBtnNextStep(index);
      });
    });
    var mskTel = function mskTel(val1) {
      return val1.replace(/\D/g, "").length === 11 ? "(00) 0 0000-0000" : "(00) 0000-00009";
    };
    var options = {
      onKeyPress: function onKeyPress(val1, e, field, options) {
        field.mask(mskTel.apply({}, arguments), options);
      }
    };
    $("#telefone").mask(mskTel, options);
    $("#cpf").mask("999.999.999-99", {
      reverse: true
    });
    $("#cep").mask("99999-999", {
      reverse: true
    });
  }

  // SIMULATOR - MONTHS SLIDER OPTIONS (STEP 1)
  var simulatorMonthsOptions = $('[data-simulator="1"] .simulator-step-months');
  if (simulatorMonthsOptions.length) {
    var simulatorMonthsOptionsItens = $('[data-simulator="1"] .simulator-step-months li');
    simulatorMonthsOptions.slick({
      slidesToShow: 4,
      dots: false,
      infinite: false,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 601,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }]
    });
    var isDeviceMobileMax480 = window.matchMedia("(max-width: 480px)").matches;
    if (isDeviceMobileMax480) {
      setTimeout(function () {
        $(simulatorMonthsOptionsItens[1]).trigger("click");
      }, 100);
    }
  }

  // SIMULATOR - MONTHS BUTTONS
  var simulatorLIMonthsOptions = document.querySelectorAll('[data-simulator="1"] .simulator-step-months li');
  if (simulatorLIMonthsOptions.length) {
    var handleClickBtnMonth = function handleClickBtnMonth(indexToChange) {
      simulatorLIMonthsOptions.forEach(function (item) {
        return item.classList.remove("active");
      });
      simulatorLIMonthsOptions[indexToChange].classList.add("active");
    };
    simulatorLIMonthsOptions.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        handleClickBtnMonth(index);
      });
    });
  }

  // SIMULATOR - MONTHS BTN MORE/LESS (STEP 1)
  var simulatorBtnLess = document.querySelector('[data-simulator="btn-less"]');
  var simulatorBtnMore = document.querySelector('[data-simulator="btn-plus"]');
  if (simulatorBtnLess && simulatorBtnMore) {
    var handleClickBtnSimulator = function handleClickBtnSimulator(event) {
      event.preventDefault();
      var typeButton = this.dataset.simulator;
      if (typeButton === "btn-plus") {
        var priceOnlyNumber = price.value.split(" ")[1].replace(".", "");
        var totalUpdated = Number(priceOnlyNumber) + 1000;
        price.value = totalUpdated;

        // $('[data-simulator="price"]').unmask().mask('R$ 000.000', {
        //   reverse: false,
        // });

        $('[data-simulator="price"]').maskMoney("mask");
      }
      if (typeButton === "btn-less") {
        var _priceOnlyNumber = price.value.split(" ")[1].replace(".", "");
        var _totalUpdated = Number(_priceOnlyNumber) - 1000;
        price.value = _totalUpdated;
        $('[data-simulator="price"]').maskMoney("mask");
      }
      var priceClean = this.value.replace(".", "").replace("R$ ", "");
      price.setAttribute("value", priceClean);
      checkPlanValueIsValid();
    };
    var checkPlanValueIsValid = function checkPlanValueIsValid() {
      var inputSimuladorValue = document.querySelector('[data-simulator="price"]');
      var priceClean = inputSimuladorValue.value.replace(".", "").replace("R$ ", "");
      if (Number(priceClean.replaceAll(".", "")) > 400000) {
        inputSimuladorValue.setAttribute("value", 400000);
        inputSimuladorValue.value = 400000;
        $('[data-simulator="price"]').maskMoney("mask");
        initToast("error", "Valor máximo do plano permitido é de: R$ 400.000");
      }
      var cond = Number(priceClean.replaceAll(".", "")) === -0;
      if (Number(priceClean.replaceAll(".", "")) < 0 || cond) {
        inputSimuladorValue.setAttribute("value", 0);
        inputSimuladorValue.value = 0;
        $('[data-simulator="price"]').maskMoney("mask");

        // initToast('error', 'Valor mínimo do plano permitido é de: R$ 100.000');
        initToast("error", "Insira um valor válido!");
      }
    };
    var price = document.querySelector('[data-simulator="price"]');

    // $('[data-simulator="price"]').mask('R$ ###.999', {
    //   reverse: true,
    // });

    $('[data-simulator="price"]').maskMoney({
      prefix: "R$ ",
      precision: 0,
      allowNegative: true,
      thousands: ".",
      decimal: ",",
      affixesStay: false
    });
    $('[data-simulator="price"]').maskMoney("mask");
    simulatorBtnMore.addEventListener("click", handleClickBtnSimulator);
    simulatorBtnLess.addEventListener("click", handleClickBtnSimulator);

    // Input on click
    var inputSimuladorValue = document.querySelector('[data-simulator="price"]');
    inputSimuladorValue.addEventListener("focus", function (event) {
      event.preventDefault();
      this.value = 0;
      $('[data-simulator="price"]').maskMoney("mask");
    });
    inputSimuladorValue.addEventListener("blur", function (event) {
      event.preventDefault();
      var priceClean = this.value.replace(".", "").replace("R$ ", "");
      this.setAttribute("value", priceClean);
      $('[data-simulator="price"]').maskMoney("mask");

      // check if the value has exceeded the limit
      checkPlanValueIsValid();
      if (priceClean < 0) {}
    });
  }

  // SIMULATOR - MONTHS BTNs "BACK STEP"
  var simulatorBtnBackStep = document.querySelectorAll('[data-simulator="back-step"]');
  if (simulatorBtnBackStep.length) {
    var handleClickBtnStep = function handleClickBtnStep(indexTarget) {
      var simulatorBox = document.querySelectorAll("[data-simulator-box]");
      var simulatorText = document.querySelectorAll("[data-simulator-text]");
      [].concat(_toConsumableArray(simulatorBox), _toConsumableArray(simulatorText)).forEach(function (item) {
        return item.classList.remove("active");
      });
      simulatorBox[indexTarget].classList.add("active");
      simulatorText[indexTarget].classList.add("active");
    };
    simulatorBtnBackStep.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        handleClickBtnStep(index);
      });
    });
  }

  // SIMULATOR RESPONSE - CARS
  if ($('[data-simulator-response="cars"]').length) {
    $('[data-simulator-response="cars"]').slick({
      slidesToShow: 4,
      dots: false,
      infinite: false,
      slidesToScroll: 1,
      arrows: true,
      autoplaySpeed: 6500,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 1199,
        variableWidth: false,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 991,
        variableWidth: false,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 685,
        settings: {
          slidesToShow: 1
        }
      }]
    });
    $('[data-simulator-response="cars"]').addClass("loaded");
  }

  // SIMULATOR RESPONSE - cashback
  if ($('[data-simulator-response="cashback"]').length) {
    $('[data-simulator-response="cashback"]').slick({
      slidesToShow: 3,
      infinite: false,
      slidesToScroll: 1,
      arrows: true,
      autoplaySpeed: 6500,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 767,
        variableWidth: false,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }

  // SIMULATOR RESPONSE - confetti
  function initConfettiAnimation() {
    var start = function start() {
      setTimeout(function () {
        confetti.start();
      }, 200);
    };
    var stop = function stop() {
      setTimeout(function () {
        confetti.stop();
      }, 3000);
    };
    start();
    stop();
  }

  // SIMULATOR RESPONSE - modal
  var modalSimulatorResponse = document.querySelector('[data-modal-name="simulator-response"]');
  if (modalSimulatorResponse) {
    var handleClickOutside = function handleClickOutside(event) {
      var isContainer = event.target === this;
      if (isContainer) {
        initConfettiAnimation();
      }
    };
    var btnClose = modalSimulatorResponse.querySelectorAll('[data-modal="close"]');
    btnClose.forEach(function (btn) {
      return btn.addEventListener("click", function () {
        initConfettiAnimation();
      });
    });
    modalSimulatorResponse.addEventListener("click", handleClickOutside);
  }

  // BLOG POST - BTN CATEGORIES CUSTOM (max: 991px)
  var blockCategories = document.querySelector("body .blog #block-6");
  if (blockCategories) {
    var handleClickBtnCategories = function handleClickBtnCategories(event) {
      event.preventDefault();
      var menu = document.querySelector("body .blog-right #block-6 ul");
      buttonCustom.classList.toggle("active");
      menu.classList.toggle("active");
    };
    var buttonCustom = document.createElement("button");
    buttonCustom.classList.add("btn-blog-filter");
    buttonCustom.innerHTML = "Categorias\n        <div>\n          <span></span>\n          <span></span>\n          <span></span>\n        </div>\n      ";
    blockCategories.appendChild(buttonCustom);
    buttonCustom.addEventListener("click", handleClickBtnCategories);
  }

  // BLOG POST - RELATED POSTS
  if ($('[data-blog-post="related-articles"] .related-articles-posts').length) {
    $('[data-blog-post="related-articles"] .related-articles-posts').slick({
      slidesToShow: 3,
      dots: false,
      slidesToScroll: 1,
      infinite: false,
      centerMode: false,
      arrows: true,
      prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
      nextArrow: "<button type='button' class='slick-next pull-right'></button>",
      responsive: [{
        breakpoint: 480,
        settings: {
          slidesToShow: 1.15
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6
        }
      }, {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1
        }
      }]
    });
  }

  // BLOG POST
  new ClipboardJS(".copiar-link");

  // CPF CONSULT
  var cpfInput = document.querySelector("[data-input-cpf]");
  if (cpfInput) {
    var handleFocusInput = function handleFocusInput(event) {
      event.preventDefault();
      var statusItens = document.querySelectorAll("[data-box-status] .status");
      statusItens.forEach(function (item) {
        return item.classList.remove("active");
      });
    };
    cpfInput.addEventListener("focus", handleFocusInput);
  }

  // AUTOMOBILES
  var autoMobilesSlick = $("[data-automobiles-list]");
  autoMobilesSlick.slick({
    slidesToShow: 4,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 6500,
    prevArrow: "<button type='button' class='slick-prev pull-left'></button>",
    nextArrow: "<button type='button' class='slick-next pull-right'></button>",
    responsive: [{
      breakpoint: 1199,
      variableWidth: false,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 991,
      variableWidth: false,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 685,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  // GLOBAL FUNCTION - AOS
  AOS.init({
    duration: 1000,
    once: false
  });
  $('[data-input="cep"]').on("keyup", function (e) {
    var cep = $(this).val();
    if (cep.length == 9) {
      $.getJSON("https://viacep.com.br/ws/" + cep + "/json/", function (data) {
        if (data.localidade && data.uf) {
          $('[name="uf"]').val(data.uf);
          $('[name="cidade"]').val(data.localidade);
          $(".cep-erro").css("visibility", "hidden");
          $('[data-input="cep"]').removeClass("error");
          $('.box-input-cep [name="cep"]').removeClass("invalid");
        } else {
          $(".cep-erro").html("");
          $(".box-input-cep").append('<span class="cep-erro" style="color: red;font-size: 12px;">CEP inválido</span>');
          $('.box-input-cep [name="cep"]').addClass("invalid");
          $('[name="uf"]').val("");
          $('[name="cidade"]').val("...");
        }
      });
    } else {
      $('[name="cidade"]').val("...");
    }
  });
  var $sliderValue = $(".range-slider[type=range]").val(),
    $sliderCounter = $(".slider-counter"),
    $rangeSlider = $(".range-slider"),
    $inputValor = $('[data-simulator="price"]');
  $rangeSlider.on("input", function () {
    $sliderValue = $(this).val();
    $sliderCounter.html(number_format($sliderValue, 2, ",", "."));
    $inputValor.val($sliderValue);
  });
  $(".tipo-credito button").on("click", function () {
    var tipo = $(this).data("tipo"),
      min = $(this).data("min"),
      max = $(this).data("max"),
      step,
      sliderCounter = $(".slider-counter");
    $(".tipo-credito button.active").removeClass("active");
    $(this).addClass("active");
    if (tipo == "parcela") {
      $('[name="tipo"]').val("parcela");
      $(".simulator-step-1 h5.tipo-seletor  span").html("da parcela");
      step = 100;
    } else {
      $('[name="tipo"]').val("credito");
      $(".simulator-step-1 h5.tipo-seletor span").html("do crédito");
      step = 1000;
    }
    $(".range-slider-container .value-min").html(number_format(min, 2, ",", "."));
    $(".range-slider-container .value-max").html(number_format(max, 2, ",", "."));
    $(".range-slider-container .range-slider").attr("min", min);
    $(".range-slider-container .range-slider").attr("max", max);
    $(".range-slider-container .range-slider").attr("step", step);
    $(".range-slider-container .range-slider").val(number_format(max / 2, 2, ",", "."));
    sliderCounter.html(number_format(max / 2, 2, ",", "."));
  });
  function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function toFixedFix(n, prec) {
        var k = Math.pow(10, prec);
        return "" + Math.round(n * k) / k;
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || "";
      s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
  }
  $(".checkbox-switch input").on("change", function () {
    if (!$(this).is(":checked")) {
      $(".checkbox-switch-msg").addClass("active");
      $(".simulator-step-btn-finish button").prop("disabled", true);
      $(".simulator-step-btn-finish button").addClass("disabled");
      $(".form-req-cpf-btn button").prop("disabled", true);
      $(".form-req-cpf-btn button").addClass("disabled");
    } else {
      $(".checkbox-switch-msg").removeClass("active");
      $(".simulator-step-btn-finish button").prop("disabled", false);
      $(".simulator-step-btn-finish button").removeClass("disabled");
      $(".form-req-cpf-btn button").prop("disabled", false);
      $(".form-req-cpf-btn button").removeClass("disabled");
    }
  });

  // ======== NOVO SIMILADOR
  if (true) {
    var handleClickBtnTooltip = function handleClickBtnTooltip(indexTarget) {
      tooltipBtns.forEach(function (btn, index) {
        if (index === indexTarget) return;
        btn.classList.remove("active");
      });
      tooltipBtns[indexTarget].classList.toggle("active");
      outsideClick(tooltipBtns[indexTarget], ["click"], function () {
        tooltipBtns[indexTarget].classList.remove("active");
      });
    };
    // simulator - scroll to box result (mobile)
    var scrollToSimulatorBoxResult = function scrollToSimulatorBoxResult(valueUpdated, forceScroll) {
      var isDevice840pxMax = window.matchMedia("(max-width: 840px)").matches;
      if (!isDevice840pxMax) return;
      var simulatorBoxOverview = document.querySelector('[data-simulator="box-overview"]');
      if (simulatorBoxOverview) {
        if (forceScroll) {
          simulatorBoxOverview.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        } else {
          oldValue = valueUpdated;
          setTimeout(function () {
            if (oldValue === valueUpdated && !simulatorBoxOverview.classList.contains("on-screen")) {
              simulatorBoxOverview.scrollIntoView({
                block: "start",
                behavior: "smooth"
              });
              simulatorBoxOverview.classList.add("on-screen");
              setTimeout(function () {
                simulatorBoxOverview.classList.remove("on-screen");
              }, 2000);
            } else {}
          }, 1200);
        }
      }
    }; // simulator - category filter tabs (credit or models)
    var changeInputTelphone = function changeInputTelphone(indexTarget) {
      var numberInput = document.querySelector('[data-input="number"]');
      var type = radioInputsContact[indexTarget].dataset.simulatorRadio;
      if (type === "cellphone" && lastOptionSelected !== "cellphone") {
        numberInput.placeholder = "(11) 91234-5678";
        $('[data-input="number"]').val("");
        $('[data-input="number"]').attr("value", "");
        lastOptionSelected = "cellphone";
      }
      if (type === "phone" && lastOptionSelected !== "phone") {
        numberInput.placeholder = "(11) 1234-5678";
        $('[data-input="number"]').val("");
        $('[data-input="number"]').attr("value", "");
        lastOptionSelected = "phone";
      }
    };
    // simulator (last step) - finish simulator (form)
    var checkFormSimulatorIsValid = function checkFormSimulatorIsValid() {
      var isAllElementsFilled = true;

      // check others inputs
      var inputsSelectsForm = document.querySelectorAll('[data-simulator="step-last"] select, [data-simulator="step-last"] input');
      inputsSelectsForm.forEach(function (item) {
        if (!item.value) {
          item.classList.add("invalid");
          setTimeout(function () {
            return item.classList.remove("invalid");
          }, 2000);
          if (item.dataset.input !== "telefone" && item.dataset.input !== "email" && item.dataset.input !== "ga_cookie_id" && item.dataset.input !== "utm_source" && item.dataset.input !== "utm_medium" && item.dataset.input !== "utm_campaign" && item.dataset.input !== "utm_content" && item.dataset.input !== "utm_term" && isAllElementsFilled) {
            isAllElementsFilled = false;
          }
        }
      });
      if (!isAllElementsFilled) {
        initToast("error", "Preencha todos os campos!");
        return false;
      }

      // check email
      var inputEmail = document.querySelector('[data-input="email"]');
      var isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail.value);
      if (!isValidEmail) {
        initToast("error", "Informe um e-mail válido!");
        inputEmail.classList.add("invalid");
        setTimeout(function () {
          return inputEmail.classList.remove("invalid");
        }, 2000);
        return false;
      }

      // check cpf
      var inputCpf = document.querySelector('[data-input="cpf"]');
      if (inputCpf) {
        var cpfClean = inputCpf.value.toString().replace(/\.|-/gm, "");
        if (!jsbrasil.validateBr.cpf(cpfClean)) {
          initToast("error", "Informe um numero de CPF válido!");
          return false;
        }
      }

      // check phone
      var radioInputsContact = document.querySelectorAll("[data-simulator-radio]");
      var radioInputContactTypeChecked = null;
      var radioInputContactTypeValue = null;
      radioInputsContact.forEach(function (input) {
        if (input.checked) {
          radioInputContactTypeChecked = input.dataset.simulatorRadio;
          radioInputContactTypeValue = input.value;
        }
      });
      if (!radioInputContactTypeChecked && !radioInputContactTypeValue) {
        initToast("error", "Informe qual é a melhor forma de contato.");
        return false;
      }

      // const recaptchaToken = document.querySelectorAll(
      //   ".g-recaptcha-response"
      // );

      // if(!recaptchaToken.value){
      //   initToast("error", "Captcha é obrigatório!");
      //   return false;
      // }

      // const phoneNumberClean = radioInputContactTypeValue
      //   .replaceAll(" ", "")
      //   .replace("(", "")
      //   .replace(")", "")
      //   .replace("-", "");

      // if (phoneNumberClean.length !== 11) {
      //   const inputPhone = document.querySelector('[data-input="number"]');

      //   initToast("error", "Informe um numero de telefone válido!");

      //   inputPhone.classList.add("invalid");

      //   setTimeout(() => inputEmail.classList.remove("invalid"), 2000);
      // }

      // if (phoneNumberClean.length !== 11) {
      //   return false;
      // }

      // check CEP
      var inputCepError = document.querySelector(".box-input-cep.error");
      var inputCep = document.querySelector('[data-input="cep"]');
      var inputCepValue = inputCep.value;
      if (inputCepError) {
        initToast("error", "Informe um numero de CEP válido!");
        inputCep.classList.add("invalid");
        setTimeout(function () {
          return inputCep.classList.remove("invalid");
        }, 2000);
        return false;
      }
      if (inputCepValue.length !== 9) {
        return false;
      }
      return isAllElementsFilled;
    };
    // simulator - tooltip
    var tooltipBtns = document.querySelectorAll('[data-tooltip="btn"]');
    tooltipBtns.forEach(function (btn, index) {
      btn.addEventListener("click", function (event) {
        event.preventDefault();
        handleClickBtnTooltip(index);
      });
    });

    // simulator - variables global
    var isFiltersFilled = false;
    var isFirstRunScriptScrollToBox = true;
    var oldValue = null;
    var isFetchedCarsModal = false;
    var simulatorCategoryFiltersTabs = document.querySelectorAll("[data-simulator-filters-tabs]");
    if (simulatorCategoryFiltersTabs.length) {
      var toggleSimulatorOptions = function toggleSimulatorOptions(indexTarget) {
        if (simulatorCategoryFiltersTabs[indexTarget].classList.contains("active")) return;
        var content = document.querySelectorAll("[data-simulator-filters-content]");
        [].concat(_toConsumableArray(simulatorCategoryFiltersTabs), _toConsumableArray(content)).forEach(function (item) {
          item.classList.remove("active");
        });
        simulatorCategoryFiltersTabs[indexTarget].classList.add("active");
        content[indexTarget].classList.add("active");
        toggleSimulatorOverview(simulatorCategoryFiltersTabs[indexTarget]);
      };
      var toggleSimulatorOverview = function toggleSimulatorOverview(tagTargetActive) {
        var simulatorBoxOverview = document.querySelector('[data-simulator="box-overview"]');
        var simulatorFiltersTabs = tagTargetActive.dataset.simulatorFiltersTabs;
        var isCreditOptionActive = simulatorFiltersTabs === "credit";
        if (simulatorBoxOverview && simulatorBoxOverview.classList.contains("filled") && isCreditOptionActive && isFiltersFilled) {
          simulatorBoxOverview.classList.add("active");
        } else {
          simulatorBoxOverview && simulatorBoxOverview.classList.remove("active");
        }
      };
      simulatorCategoryFiltersTabs.forEach(function (tab, index) {
        tab.addEventListener("click", function (event) {
          event.preventDefault();
          toggleSimulatorOptions(index);
        });
      });
    }

    // ============ simulator - input range - credit value
    var inputRangeCredit = document.querySelector('[data-simulator-filters="input-range-credit"]');
    var simulatorTimeSelect = document.querySelector('[data-simulator-filters="time-select"]');
    if (inputRangeCredit && simulatorTimeSelect) {
      var updateCreditPositionRadioAndValues = function updateCreditPositionRadioAndValues() {
        inputRangeCredit.removeAttribute("step");

        // positioning available value in input
        for (var index = 0; index < FetchResponseCreditValues.length; index++) {
          if (FetchResponseCreditValues[index] > inputRangeCredit.value) {
            inputRangeCredit.value = FetchResponseCreditValues[index];
            break;
          }
        }

        // positioning filled line
        // and update tooltip value
        var inputRangeLine = document.querySelector('[data-simulator-filters="range-credit-line"]');
        var inputRangeValue = document.querySelector('[data-simulator-filters="range-value-box"]');
        var newValueFormatted = number_format($(inputRangeCredit).val(), 2, ",", ".");
        var newValue = Number((inputRangeCredit.value - inputRangeCredit.min) * 100 / (inputRangeCredit.max - inputRangeCredit.min));
        var newPosition = 10 - newValue * 0.2;

        // line position
        inputRangeLine.style.width = "calc(".concat(newValue, "% + (").concat(newPosition, "px))");

        // line value e position
        inputRangeValue.innerHTML = "<span>R$ ".concat(newValueFormatted, "</span>");
        inputRangeValue.style.left = "calc(".concat(newValue, "% + (").concat(newPosition, "px))");
      };
      // ============ simulator - input - credit value
      var updateSimulatorCreditRangeValue = function updateSimulatorCreditRangeValue() {
        updateCreditPositionRadioAndValues();

        // fetch 'prazo' select
        var oldValue = inputRangeCredit.value;
        var filtersBox = document.querySelector('[data-simulator="filters"]');
        setTimeout(function () {
          var valueUpdated = inputRangeCredit.value;
          if (oldValue === valueUpdated && !filtersBox.classList.contains("loading")) {
            updateTimesSelectOptions(valueUpdated);
            resetDataOverviewBox();
          }
        }, 700);
      };
      var updateTimesSelectOptions = function updateTimesSelectOptions(value) {
        var simulatorTimeBox = document.querySelector('[data-simulator-filters="box-time-select"]');
        $.ajax({
          url: "".concat(baseUrl, "/wp-json/ct/v1/simulador/get-prazo"),
          data: {
            credito: value
          },
          dataType: "json",
          type: "POST",
          cache: false,
          beforeSend: function beforeSend() {
            simulatorTimeBox.classList.add("loading");
          },
          success: function success(response) {
            function sortfunction(a, b) {
              return a - b;
            }
            var timeSelect = document.querySelector('[data-simulator-filters="time-select"]');
            Array.from(timeSelect.options).forEach(function (option) {
              option.remove();
            });
            var timeResponseArray = Object.keys(response).map(function (item) {
              return response[item].prazo;
            });
            var timeResponseArraySorted = timeResponseArray.sort(sortfunction);
            timeSelect.innerHTML = "\n              <option value=\"\" disabled selected>\n                Definir prazo\n              </option>\n              ";
            timeSelect.innerHTML += timeResponseArraySorted.map(function (item) {
              return "<option value=\"".concat(item, "\">").concat(item, " meses</option>");
            }).join("");

            // step 2
            var btnFilterAgain = document.querySelector('[data-simulator-filters="btn-filter-again"]');
            var boxFilterAgain = document.querySelector('[data-simulator-filters="box-filter-again"]');
            if (btnFilterAgain) btnFilterAgain.href = "";
            if (boxFilterAgain && boxFilterAgain.value) {
              boxFilterAgain.classList.remove("inactive");
            }
          },
          error: function error(XMLHttpRequest, textStatus, errorThrown) {
            // console.log(errorThrown);
          },
          complete: function complete() {
            // Hide loader
            setTimeout(function () {
              simulatorTimeBox.classList.remove("loading");
            }, 600);
          }
        });
      };
      var resetDataOverviewBox = function resetDataOverviewBox() {
        var simulatorBoxOverview = document.querySelector('[data-simulator="box-overview"]');
        if (simulatorBoxOverview) {
          var isSimulatorBoxOverviewActive = simulatorBoxOverview.classList.contains("active");

          // hide simulatorBox
          if (isSimulatorBoxOverviewActive) {
            simulatorBoxOverview.classList.remove("active");
          }

          // reset data infos
          var simulatorCredit = document.querySelectorAll('[data-simulator-overview="credit"]');
          var simulatorMonthsArea = document.querySelector('[data-simulator-overview="months"]');
          var simulatorInstallments = document.querySelector('[data-simulator-overview="installments"]');
          var simulatorFee = document.querySelector('[data-simulator-overview="fee-area"]');
          var simulatorReserveFund = document.querySelector('[data-simulator-overview="reserve-fund-area"]');
          var simulatorMonthlyInsurance = document.querySelector('[data-simulator-overview="monthly-insurance-area"]');
          var simulatorCashback = document.querySelector('[data-simulator-overview="cashback-area"]');
          var simulatorCarImg = document.querySelector('[data-simulator-overview="car-img"]');
          var simulatorCarName = document.querySelector('[data-simulator-overview="car-name"]');
          var simulatorBtnInterest = document.querySelector('[data-simulator-overview="btn-interest"]');
          var simulatorBtnSeeOptions = document.querySelector('[data-simulator-overview="see-options"]');
          simulatorCredit.forEach(function (item) {
            item.innerText = "----";
          });
          [simulatorMonthsArea, simulatorInstallments, simulatorFee, simulatorReserveFund, simulatorMonthlyInsurance, simulatorCashback, simulatorCarName].forEach(function (item) {
            item.innerText = "----";
          });
          simulatorCarImg.src = "";
          simulatorBtnInterest.href = "";
          simulatorBtnSeeOptions.href = "";
          simulatorBoxOverview.classList.remove("filled");
        }

        // step 2
        var btnFilterAgain = document.querySelector('[data-simulator-filters="btn-filter-again"]');
        var boxFilterAgain = document.querySelector('[data-simulator-filters="box-filter-again"]');
        if (btnFilterAgain) btnFilterAgain.href = "";
        if (boxFilterAgain) {
          boxFilterAgain.classList.add("inactive");
        }
      };
      var updateSimulatorMonths = function updateSimulatorMonths() {
        function updateOverviewResult() {
          var boxStep1 = document.querySelector('[data-simulator="step-1"]');
          var boxStep2 = document.querySelector('[data-simulator="step-2"]');
          if (boxStep1) {
            var filtersBox = document.querySelector('[data-simulator="filters"]');
            var simulatorBoxOverview = document.querySelector('[data-simulator="box-overview"]');
            var inputCreditValue = document.querySelector('[data-simulator-filters="input-range-credit"]').value;
            var selectTimeValue = document.querySelector('[data-simulator-filters="time-select"]').value;
            var requestData = {
              credito: inputCreditValue,
              tempo: selectTimeValue
            };
            $.ajax({
              url: "".concat(baseUrl, "/wp-json/ct/v1/simulador/get-dados-credito"),
              data: requestData,
              // ====== forçar erro para mostrar o toast
              dataType: "json",
              type: "POST",
              cache: false,
              beforeSend: function beforeSend() {
                simulatorBoxOverview.classList.add("active");
                filtersBox.classList.add("loading");
                simulatorBoxOverview.classList.add("loading");
              },
              success: function success(response) {
                var isResponseDataToShow = Object.keys(response)[0];
                if (!isResponseDataToShow) {
                  initToast("warning", "Ocorreu um erro ao buscar as informações, tente novamente");
                  return;
                }
                var _response$Object$keys = response[Object.keys(response)[0]],
                  id = _response$Object$keys.id,
                  valor_da_carta_de_credito = _response$Object$keys.valor_da_carta_de_credito,
                  prazo = _response$Object$keys.prazo,
                  parcela = _response$Object$keys.parcela,
                  taxa_arredondada = _response$Object$keys.taxa_arredondada,
                  fundo_de_reserva = _response$Object$keys.fundo_de_reserva,
                  seguro = _response$Object$keys.seguro,
                  cashback_toyota = _response$Object$keys.cashback_toyota,
                  tipo_de_carta = _response$Object$keys.tipo_de_carta,
                  veiculo_ = _response$Object$keys.veiculo_,
                  veiculo = _response$Object$keys.veiculo;
                var simulatorCredit = document.querySelectorAll('[data-simulator-overview="credit"]');
                var simulatorMonthsArea = document.querySelector('[data-simulator-overview="months"]');
                var simulatorInstallments = document.querySelector('[data-simulator-overview="installments"]');
                var simulatorFee = document.querySelector('[data-simulator-overview="fee-area"]');
                var simulatorReserveFund = document.querySelector('[data-simulator-overview="reserve-fund-area"]');
                var simulatorMonthlyInsurance = document.querySelector('[data-simulator-overview="monthly-insurance-area"]');
                var simulatorCashback = document.querySelector('[data-simulator-overview="cashback-area"]');
                var simulatorCarImg = document.querySelector('[data-simulator-overview="car-img"]');
                var simulatorCarName = document.querySelector('[data-simulator-overview="car-name"]');
                var simulatorBtnInterest = document.querySelector('[data-simulator-overview="btn-interest"]');
                var simulatorBtnSeeOptions = document.querySelector('[data-simulator-overview="see-options"]');
                simulatorCredit.forEach(function (item) {
                  item.innerText = valor_da_carta_de_credito;
                });
                simulatorMonthsArea.innerText = "".concat(prazo, " meses");
                simulatorInstallments.innerText = "".concat(parcela, "*");
                simulatorFee.innerText = "".concat(taxa_arredondada, "% ao m\xEAs");
                simulatorReserveFund.innerText = fundo_de_reserva;
                simulatorMonthlyInsurance.innerText = "".concat(seguro, "%");
                simulatorCashback.innerText = cashback_toyota;
                if (veiculo_.match(/%/)) {
                  simulatorCarName.innerHTML = "Correspondente a <strong class=\"name\" data-simulator-overview=\"car-name\">".concat(veiculo_, "</strong>, que pode ser utilizado para compra de ve\xEDculos seminovos e certificados em nossa rede.");
                } else {
                  simulatorCarName.innerHTML = "voc\xEA pode comprar um <strong class=\"name\" data-simulator-overview=\"car-name\">".concat(veiculo.titulo, "</strong>.");
                }
                simulatorCarImg.src = veiculo.imagem;
                simulatorBtnInterest.href = "".concat(baseUrl, "/simulador-etapa-3/?interesse=").concat(btoa(id));
                simulatorBtnSeeOptions.href = "".concat(baseUrl, "/simulador-etapa-2/?credito=").concat(btoa(valor_da_carta_de_credito + "|" + prazo));
                simulatorBoxOverview.classList.add("filled");
              },
              error: function error(XMLHttpRequest, textStatus, errorThrown) {},
              complete: function complete() {
                setTimeout(function () {
                  filtersBox.classList.remove("loading");
                  simulatorBoxOverview.classList.remove("loading");
                }, 600);
              }
            });
          }
          if (boxStep2) {
            var _inputCreditValue = document.querySelector('[data-simulator-filters="input-range-credit"]').value;
            var _selectTimeValue = document.querySelector('[data-simulator-filters="time-select"]').value;
            var _requestData = {
              credito: _inputCreditValue,
              tempo: _selectTimeValue
            };
            $.ajax({
              url: "".concat(baseUrl, "/wp-json/ct/v1/simulador/get-dados-credito"),
              data: _requestData,
              // ====== forçar erro para mostrar o toast
              dataType: "json",
              type: "POST",
              cache: false,
              beforeSend: function beforeSend() {},
              success: function success(response) {
                var isResponseDataToShow = Object.keys(response)[0];
                if (!isResponseDataToShow) {
                  initToast("warning", "Ocorreu um erro ao buscar as informações, tente novamente");
                  return;
                }
                var _response$Object$keys2 = response[Object.keys(response)[0]],
                  valor_da_carta_de_credito = _response$Object$keys2.valor_da_carta_de_credito,
                  prazo = _response$Object$keys2.prazo;

                // step 2
                var boxFilterAgain = document.querySelector('[data-simulator-filters="box-filter-again"]');
                var btnFilterAgain = document.querySelector('[data-simulator-filters="btn-filter-again"]');
                if (btnFilterAgain) {
                  btnFilterAgain.href = "".concat(baseUrl, "/simulador-etapa-2/?credito=").concat(btoa(valor_da_carta_de_credito + "|" + prazo));
                  boxFilterAgain.classList.remove("inactive");
                }
              },
              error: function error(XMLHttpRequest, textStatus, errorThrown) {},
              complete: function complete() {}
            });
          }
        }
        updateOverviewResult();
        if (isFirstChangeOnTimeSelect) {
          isFiltersFilled = true;
          if (isFiltersFilled) {
            setTimeout(function () {
              scrollToSimulatorBoxResult(null, true);
            }, 500);
          }
        } else {
          scrollToSimulatorBoxResult(null, true);
        }
        isFirstChangeOnTimeSelect = false;
      };
      var FetchResponseCreditValues = result; // -> result: PHP variable

      var isFirstChangeCreditValue = true;
      inputRangeCredit.setAttribute("step", FetchResponseCreditValues[0]);
      updateCreditPositionRadioAndValues();
      inputRangeCredit.addEventListener("input", updateSimulatorCreditRangeValue);
      updateTimesSelectOptions(inputRangeCredit.value);

      // ============ simulator - select - time
      var isFirstChangeOnTimeSelect = true;
      simulatorTimeSelect.addEventListener("change", updateSimulatorMonths);
    }

    // simulador (step 2) - filter again (side-filter)
    var btnOpenSideFilter = document.querySelector('[data-simulator="btn-filter-again"]');
    if (btnOpenSideFilter) {
      var toggleAsideFilter = function toggleAsideFilter(event) {
        event.preventDefault();
        var sideFilter = document.querySelector('[data-simulator="side-filter"]');
        sideFilter.classList.toggle("active");
        if (sideFilterOverlay.classList.contains("active")) {
          setTimeout(function () {
            sideFilterOverlay.classList.remove("active");
          }, 300);
        } else {
          sideFilterOverlay.classList.add("active");
        }
      };
      var btnCloseFilter = document.querySelector('[data-simulator="btn-close-side-filter"]');
      var sideFilterOverlay = document.querySelector('[data-simulator="side-filter-overlay"]');
      btnOpenSideFilter.addEventListener("click", toggleAsideFilter);
      btnCloseFilter.addEventListener("click", toggleAsideFilter);
      sideFilterOverlay.addEventListener("click", toggleAsideFilter);
    }

    // simulator (last step)

    var _mskTel = function _mskTel(val1) {
      return val1.replace(/\D/g, "").length === 11 ? "(00) 0 0000-0000" : "(00) 0000-00009";
    };
    var _options = {
      onKeyPress: function onKeyPress(val1, e, field, options) {
        field.mask(_mskTel.apply({}, arguments), options);
      }
    };
    $('[data-input="number"]').mask(_mskTel, _options);

    // $('[data-form="cellphone"]').mask(mskTel, options);

    $('[data-input="cpf"]').mask("999.999.999-99");
    $('[data-input="cep"]').mask("99999-999");
    var radioInputsContact = document.querySelectorAll("[data-simulator-radio]");
    var lastOptionSelected = "cellphone";
    radioInputsContact.forEach(function (input, index) {
      input.addEventListener("click", function () {
        changeInputTelphone(index);
      });
    });
    var simulatorFormLastStep = $('[data-simulator="step-last"]');

    // (last step) - finish simulator (form)
    if (simulatorFormLastStep.length) {
      var modalFinal = $('[data-simulator="modal-final"]');
      simulatorFormLastStep.submit(function (e) {
        var boxStep3 = $('[data-simulator-steps="3"]');
        var boxBtnSubmit = $('[data-simulator="box-submit"]');
        e.preventDefault();

        //valida campos
        if (!checkFormSimulatorIsValid()) {
          return;
        }
        var form = $(this);
        var formData = new FormData(this);
        var wp_nonce = $(".wp_nonce").val();

        /* Faz a Requisição */
        $.ajax({
          url: form.attr("action"),
          data: formData,
          dataType: "json",
          type: "POST",
          cache: false,
          contentType: false,
          processData: false,
          headers: {
            authorization: wp_nonce
          },
          beforeSend: function beforeSend() {
            boxBtnSubmit.addClass("loading");
          },
          success: function success(response) {
            if (!response.status) {
              initToast("error", "Captcha é obrigatório!");
              return false;
            }
            boxStep3.addClass("active");
            modalFinal.addClass("active");
          },
          error: function error(XMLHttpRequest, textStatus, errorThrown) {
            boxBtnSubmit.removeClass("loading");
          },
          complete: function complete() {
            boxBtnSubmit.removeClass("loading");
          }
        });
      });
    }
  }
});

},{}]},{},[1]);
